import React from 'react';
import '../../styles/home/Banner1.css';
import img from '../../assets/images/Banner.jpg';
import videoicon from '../../assets/logo/VideoIcon.png';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
const Banner1 = () => {
  return (
    <div className='banner1-main'>
      <div className='banner1-s1'>
            <div style={{background: 'transparent'}}>
                   <h1>Welcome to Democratyzwork: Personalized 1:1 Training, Tailored Just for You</h1> 
                   <p style={{marginTop:"3rem"}}>Unlock your potential with Democratyzwork, where we transform tech education through personalized 1:1 training.</p>
            </div>
            <div className='banner1-btn'>
                {/* <button className='banner1-btn1'>Get Started</button> */}
                <ScrollLink to={'features'}  smooth={true} className='banner1-btn1' duration={500}>
         Get Started
        </ScrollLink>
               {/* <div className='banner1-btn2'>
               <img src={videoicon} alt=''/>
               <button>Watch Video</button>
               </div> */}
            </div >
            <div className='hb1' >
            <div >
                <p className='p1'>9,500+</p>
                <p  className='p2'>Education Partners</p>  
            </div>
            <div>
                <p className='p1'>10k</p>
                <p  className='p2'>Active Students</p>
            </div>
            <div>
                <p className='p1'>24/7</p>
                <p  className='p2'>All Day Consultation</p>
            </div>
            </div>
      </div>
      <div className='banner1-s2'>
            <img src={img} alt='' />
      </div>
    </div>
  )
}

export default Banner1
