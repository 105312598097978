import React from 'react';
import '../styles/Footer.css';
// import img from '../assets/logo/Logo.png';
// import { BsDiscord } from 'react-icons/bs';
// import {BiLogoLinkedin} from 'react-icons/bi';
// import {AiOutlineTwitter} from 'react-icons/ai';
// import { Link } from 'react-router-dom';



const Footer = () => {
  return (
    
    // <div className='footer-main'>
    //     <div className='footer-s1'>
    //     <div className='header-s1'>
    //             <img src={img} alt=''/>
    //             <span>Democratyzwork</span>
    //     </div>
    //     <div style={{fontFamily:"Manrope"}}>
    //     Lorem ipsum dolor sit amet consectetur. Ultricies aliquam nisl tempor lorem. 
    //     </div>
    //     <div>
    //         <BsDiscord style={{color:'#4338CA',width: "22.667px",height: "22.667px"}} className='icons'/>
    //         <BiLogoLinkedin style={{color:'#4338CA',width: "22.667px",height: "22.667px"}} className='icons'/>
    //         <AiOutlineTwitter style={{color:'#4338CA',width: "22.667px",height: "22.667px"}} className='icons'/>
    //     </div>
    //     </div>
    //     <div className='footer-s2'>
    //         <h4>Quick Links</h4>
    //         <Link>Mentors</Link>
    //         <Link>Featues</Link>
    //         <Link>Courses</Link>
    //         <Link>Contact</Link>
    //     </div>
    //     <div className='footer-s2'>
    //     <h4>About</h4>
    //     {/* <Link>Our Contact</Link> */}
    //         <Link>Career with Us</Link>
    //         <Link>Our Mission</Link>
    //     </div>
    //     <div className='footer-s2'>
    //     <h4>Career</h4>
    //     <Link>Internship</Link>
    //         <Link>Apply Job</Link>
    //         <Link>Job Vacancies</Link>
    //     </div>
    //     <div className='footer-s2'>
    //     <h4>Others</h4>
    //     <Link>Blog</Link>
    //         <Link>FAQ</Link>
    //         <Link>Terms Condition</Link>
    //         <Link>Privacy Policy</Link>
    //     </div>
    // </div>
    <span className='footer-span'>
        © 2023 Democratyzwork - All Right Reserved
    </span>
   
  )
}

export default Footer
