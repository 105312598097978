import React from 'react';
import img from '../assets/logo/Logo.png';
import '../styles/Header.css';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
const Header = () => {
  return (
    <nav className='header-main'>
        <div className='header-s1'>
            <img src={img} alt=''/>
            <span>Democratyzwork</span>
        </div>
        <div className='header-s2'>
            <Link to={'/'}>Home</Link>
            <ScrollLink to={'aboutus'} className='nav-link' smooth={true} duration={500}>
     About us
        </ScrollLink>
         
      
        <ScrollLink to={'features'} className='nav-link' smooth={true} duration={500}>
    Courses
        </ScrollLink>
        
        </div>
        <div className='header-s3'>
        <Link to={'/login'}> <button className='btn-2'>Login</button></Link> 
          
          
        <Link to={'/contact'}> <button className='btn-2'>Contact Us</button></Link> 
          
        </div>
    </nav>
  )
}

export default Header
